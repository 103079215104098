<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-break-point="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-divider class="mb-1" />

    <v-list dense nav>
      <v-list-item>
        <v-list-item-avatar class="align-self-center" color="white" contain>
          <v-img
            v-if="guest"
            src="https://demos.creative-tim.com/vuetify-material-dashboard/favicon.ico"
            max-height="30"
          />
          <img
            v-if="!guest"
            :src="getImage('/' + setting.logo_path_url)"
            max-height="30"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="display-1" v-text="profile.title" />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <center>
      <v-tooltip right color="info" v-if="!guest">
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            :color="getColor(user.member_id)"
            dark
            v-bind="attrs"
            v-on="on"
            @click="isLicence"
          >
            <span class="black--text">
              <v-text v-if="user.member_id == 1">PLATINUM</v-text>
              <v-text v-else-if="user.member_id == 2">GOLD</v-text>
              <v-text v-else-if="user.member_id == 3">SILVER</v-text>
              <v-text v-else>FREE</v-text>
            </span>
          </v-chip>
        </template>
        <span>Your license</span>
      </v-tooltip>
    </center>
    <v-list expand nav>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item">
          <!--  -->
        </base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>

    <template v-slot:append v-if="guest">
      <base-item
        :item="{
          title: $t('Login'),
          icon: 'mdi-login',
          to: '/login',
        }"
      />
    </template>

    <div class="text-center">
      <v-dialog v-model="progressBar" width="100" hide-overlay>
        <v-progress-circular
          :size="60"
          color="primary"
          indeterminate
          align-center
          justify-center
        >
        </v-progress-circular>
      </v-dialog>
    </div>
    <div class="text-center">
      <v-dialog v-model="dialogLicence" max-width="500">
        <v-card color="#1b5e20">
          <v-col class="text-right">
            <v-btn
              icon
              rounded
              color="white"
              outlined
              elevation="5"
              large
              @click="dialogLicence = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
          <v-card max-height="400" max-width="500" color="white">
            <v-container fluid>
              <v-form ref="formLicence" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="licence"
                  label="Licence"
                  placeholder="input licence"
                  length="6"
                >
                </v-text-field>
              </v-form>
              <v-card-actions>
                <v-btn color="#1b5e20" @click="saveLicence" class="white--text">
                  OK
                  <v-icon right color="white">mdi-key</v-icon>
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-card>
      </v-dialog>
    </div>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    items: [
      {
        icon: "mdi-view-dashboard",
        title: "Dashboard",
        to: "/",
      },
      {
        icon: "mdi-point-of-sale",
        title: "Point Of Sale",
        to: "/pages/point-of-sale",
      },
      {
        icon: "mdi-account-group",
        title: "Users",
        to: "/pages/user-tables",
      },
      {
        icon: "mdi-shape-plus",
        title: "Categories",
        to: "/pages/category-tables",
      },
      {
        icon: "mdi-purse",
        title: "Purchase Order",
        to: "/pages/purchase-order-tables",
      },
      {
        icon: "mdi-storefront-edit",
        title: "Products",
        to: "/pages/product-tables",
      },
      {
        icon: "mdi-cart-arrow-down",
        title: "Orders",
        to: "/pages/order-tables",
      },
      {
        icon: "mdi-gift",
        title: "Promotion",
        to: "/pages/promotion-tables",
      },
      {
        icon: "mdi-clipboard-flow",
        title: "Cash Flow",
        to: "/pages/chas-flow-tables",
      },
      {
        icon: "mdi-information",
        title: "Media Information",
        to: "/pages/information-tables",
      },
      {
        icon: "mdi-truck",
        title: "Courier",
        to: "/pages/courier-tables",
      },
      {
        icon: "mdi-unicode",
        title: "Code Reveral Iot",
        to: "/pages/code-reveral-iot-tables",
      },
      {
        icon: "mdi-license",
        title: "Licence",
        to: "/pages/licence-tables",
      },
      {
        icon: "mdi-cogs",
        title: "Store Setting",
        to: "/pages/store-setting-tables",
      },
    ],
    dialogLicence: false,
    licence: "",
    progressBar: false,
  }),

  computed: {
    ...mapState(["barColor", "barImage"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    computedItems() {
      return this.items.map(this.mapItem);
    },
    profile() {
      return {
        avatar: true,
        title: "Indorobotika",
      };
    },
    ...mapGetters({
      guest: "auth/guest",
      user: "auth/user",
      dialogStatus: "dialog/status",
      currentComponent: "dialog/component",
      setting: "setting/setting",
    }),
    dialog: {
      get() {
        return this.dialogStatus;
      },
      set(value) {
        this.setDialogStatus(value);
      },
    },
  },

  methods: {
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },
    ...mapActions({
      setDialogStatus: "dialog/setStatus",
      setDialogComponent: "dialog/setComponent",
      setAuth: "auth/set",
      setAlert: "alert/set",
      setSetting: "setting/set",
    }),
    getColor(member) {
      if (member == 1) return "white";
      else if (member == 2) return "yellow";
      else if (member == 3) return "blue";
      else return "gree";
    },
    isLicence() {
      this.dialogLicence = true;
    },
    saveLicence() {
      this.progressBar = true;
      let formData = new FormData();
      formData.set("licence", this.licence);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      this.axios
        .post("/licence/upgrade", formData, config)
        .then((response) => {
          let { data } = response;
          this.setAuth(data.data);
          this.setAlert({
            status: true,
            text: data.message,
            color: data.status,
          });
          this.dialogLicence = false;
        })
        .catch((error) => {
          let { data } = error;
          this.setAlert({
            status: true,
            text: data.message,
            color: "error",
          });
          this.isLicence();
          this.dialogLicence = false;
        });
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
